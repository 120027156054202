import React from "react"

interface ParagraphProps {
  classname?: string
  whitespace?: boolean
}

export const Paragraph: React.FC<ParagraphProps> = ({
  classname,
  whitespace = true,
  children,
}) => {
  return (
    <p
      className={`
      text-homepageMantraText ${
        whitespace ? "whitespace-pre-line" : ""
      } ${classname} `}
    >
      {children}
    </p>
  )
}
