import { graphql } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { Paragraph } from "../components/Paragraph/Paragraph"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface NetiquetteProps {
  data: {
    cms: {
      nettiquettes: {
        nodes: {
          nettiquetteFields: {
            heading: string
            text: string
          }
        }[]
      }
    }
  }
}

export const Netiquette: React.FC<NetiquetteProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <div className="mb-20 flex flex-col items-center">
        {data.cms.nettiquettes.nodes.map(
          ({ nettiquetteFields: { heading, text } }, index) => (
            <div key={index} className="flex flex-col items-center">
              <PageTitle marginBottom="mb-4" lineWidth="w-72">
                {heading}
              </PageTitle>
              <Paragraph
                whitespace={false}
                classname="font-titilliumWeb text-base lg:w-7/12 text-center text-headings leading-7 font-normal my-8 mb-24 w-8/12"
              >
                {text}
              </Paragraph>
            </div>
          ),
        )}
      </div>
    </Layout>
  )
}
export default Netiquette

export const query = graphql`
  query MyQueryNettiquette {
    cms {
      nettiquettes(where: { orderby: { field: DATE, order: ASC } }) {
        nodes {
          nettiquetteFields {
            heading
            text
          }
        }
      }
    }
  }
`
